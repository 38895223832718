module.exports = class espaceClient extends require("common/appMenuNavigation") {
	constructor() {
		super();
	}
	initialize() {
		this.togglePopin()
	}
	togglePopin() {
		// Définition des variables et des états
		let btnEC = document.getElementById('btnAccountEc');
		let urlPopin = '/EspaceClient/Account/popinConnexion';
		let popin = document.getElementById('espace-client');
		let popinIsLoaded = false; // par défaut #espace-client est vide
		let popinIsActive = false; // par défaut la popin est masquée

		if (btnEC != null) {
			btnEC.addEventListener('click', function () {
				// Maj des états au click
				popinIsLoaded = popin.innerHTML == '' ? false : true;
				popinIsActive = popin.classList.contains('is-active');

				// Si la popin n'est pas loadée,
				// on fait la requête ajax
				if (!popinIsLoaded) {
					var httpRequest = new XMLHttpRequest();
					httpRequest.onreadystatechange = function (data) {
						popin.innerHTML = data.target.response;
						popin.classList.add('is-active');
					};
					httpRequest.open("GET", urlPopin);
					httpRequest.send();
				}

				// On intervertit la visibilité

				if (popinIsActive) {
					delete document.body.dataset.popin;
					popin.classList.remove('is-active');
				}
				else {
					document.body.dataset.popin = 'ec';
					popin.classList.add('is-active');
				}
			});
		}
	}
}
/**
 * @univers Espace client
 * @description Affichage de la popin de connexion à l'Espace client
 *
 */

"use strict";

const ec = {

	togglePopin: function () {
		// Définition des variables et des états
		let btnEC = document.getElementById('btnAccountEc');
		let urlPopin = '/EspaceClient/Account/popinConnexion';
		let popin = document.getElementById('espace-client');
		let popinIsLoaded = false; // par défaut #espace-client est vide
		let popinIsActive = false; // par défaut la popin est masquée

		if (btnEC != null) {
			btnEC.addEventListener('click', function () {
				// Maj des états au click
				popinIsLoaded = popin.innerHTML == '' ? false : true;
				popinIsActive = popin.classList.contains('is-active');

				function setIsLogged() {
					if (document.querySelector('.is-connected') !== null) {
						document.getElementById('header').dataset.logged = 'True';
					} else {
						document.getElementById('header').dataset.logged = 'False';
					}
				}

				// Si la popin n'est pas loadée,
				// on fait la requête ajax
				if (!popinIsLoaded) {
					var httpRequest = new XMLHttpRequest();
					httpRequest.onreadystatechange = function (data) {
						popin.innerHTML = data.target.response;
					};
					httpRequest.open("GET", urlPopin);
					httpRequest.send();
					httpRequest.onloadend = document.getElementById('id1').value === 'home' && setIsLogged;
				}

				// On intervertit la visibilité
				popin.classList.toggle('is-active');

				if (document.body.dataset.open !== undefined) {

					if (!helpers.isMobile() && !helpers.isTouchDevice()) {
						delete document.body.dataset.open;
						document.querySelectorAll('.onglet').forEach(el => el.classList.remove('is-show'));
						document.querySelectorAll('.item--section').forEach(el => el.setAttribute('aria-expanded', false))
					} else {
						document.getElementById('header').dataset.espaceClient = 'open';
					}
				}
			});
		}
	},

	init: function () {
		this.togglePopin()
	}
}

module.exports = ec;
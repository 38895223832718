///
/// Validation des formulaires
///


/// HTML TEMPLATE BASIQUE
/// {name} : variable à remplir
/// [[paramètre]] : attribut à compléter/ajouter
/// <form class="formulaire" id="{idFormulaire}">
/// <div class="field">
/// 	<label for="" class="field__label">Libelle</label>
/// 	[[<div data-form-boxinput="" class="box-input">{Indicatif téléphonique}</div>]]
/// 	<select name="" id="" class="field__select" [[data-form-select="[''||civilite]"]] [[data-form-indicatif]]></select>
/// </div>
/// <div class="field">
/// 	<div class="field__label">libelle</div>
/// 	<input type="[[text||tel||email]]" class="field__input" [[required="true"]] [[pattern=""]]>
// </div>
/// </form>

/// CONFIGURATION PAR DÉFAULT
const formulaireDefaults = {
	hasCivilite: false,
	hasTelephone: false,
	hasScrollToFirstError: true,
	attribut: {
		boxinput: '[data-form-boxinput]',
		btnValidation: '[data-form-submit]',
		indicatif: '[data-form-indicatif]',
		select: '[data-form-select]'
	},
	classes: {
		disabled: 'is-disabled',
		error: 'is-error',
		errorField: 'field-validation-error'
	},
	selectors: {
		fieldsList: '.field__select, .field__input, .field__textarea',
		fieldClass: '.field',
		fieldClassName: 'field',
		fieldActiveClassName: 'form-field--is-active',
		fieldFilledClassName: 'form-field--is-filled',
		fieldErrorClassName: 'form-field--is-error',
		classValidation: 'field-validation-error'
	},
	msg: {
		charactersForbidden: /[a-zA-Z]+/g,
		indicatif: 'Indicatif obligatoire.',
		noTelephone: 'Vous devez saisir au moins un numéro de téléphone.',
		formatTelephone: 'Le format de votre numéro de téléphone est invalide.',
		fields: {
			indicatif: {
				mobile: 'Inscription_Coordonnees_CodeIsoForMobile',
				fixe: 'Inscription_Coordonnees_CodeIsoForFixe'
			},
			telephone: {
				mobile: 'Inscription_Coordonnees_TelephonePortable',
				fixe: 'Inscription_Coordonnees_TelephoneFixe'
			}
		},
		validationIdMsg: {
			mobile: 'validationMessageTelMobile',
			fixe: 'validationMessageTelFixe'
		},
	},
	regex: {
		charactersAlpha: /[a-zA-Z]+/g
	},
	regexTel: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
}

class Formulaire {
	constructor(settings) {
		/// On surcharge {settings} par {defaults}
		/// les propriétés de {defaults} ne sont ajoutées que si elles n'existent pas
		this.settings = {
			...formulaireDefaults,
			...settings
		};
	}

	initialize(idFormulaire) {
		let self = this;
		let settings = this.settings;

		const form = document.getElementById(idFormulaire);
		let btn = document.querySelector(settings.attribut.btnValidation);
		let fields = Array.from(form.elements);

		if (btn.dataset.formSubmit !== '') {
			btn = document.querySelector(`[data-form-submit=${idFormulaire}]`);
		}

		/// Disable Default Form Validation
		form.noValidate = true;

		/// on  ne garde que les champs ayant un attribut required
		const fieldsRequired = fields.filter(field => field.required);
		fieldsRequired.forEach(el => {
			self.validateField(el);
		});

		/// On valide les champs optionnels non vides
		const fieldsOptionnalNoEmpty = fields.filter(field => field.dataset.optionnal);
		fieldsOptionnalNoEmpty.forEach(el => {
			el.addEventListener('change', function (element) {
				element.target.value != '' && self.validateField(el);
			})

		});

		/// Gestion de l'affichage de l'indicatif téléphonique
		/// Appel de la méthode de validation
		if (settings.hasTelephone) {
			this.indicatifTelephonique(false, null);
			const fieldsTel = document.querySelectorAll('[type="tel"]');
			const fieldsIndicatif = document.querySelectorAll(settings.attribut.indicatif);
			const elements = Array.prototype.slice.call(fieldsTel).concat(Array.prototype.slice.call(fieldsIndicatif));
			elements.forEach(el => {
				self.validateField(el);
			});
		}

		/// Gestion de l'affichage d'erreurs au submit
		form.addEventListener('submit', function (e) {
			// On n'affiche pas les messages d'erreur lorsque l'on clique sur un bouton précédent
			if (e.submitter.name !== 'precedent' && e.submitter.dataset.dataFormRequete !== 'fetch') {
				if (!self._isFormValid(form)) {
					/// Le formulaire est invalide, on stoppe le submit
					e.preventDefault();
					console.error('Le formulaire est invalide');
					self.validationForm(form);
				}
			} else {
				console.log(e)
			}
		})
	}

	/// @submit
	/// Function appelée lorsque l'utilisateur soumet un formulaire
	/// mais que ce dernier n'est pas valide
	validationForm(form) {
		let self = this;
		let settings = this.settings;

		/// Gestion des erreurs génériques
		let firstError = null;
		let fieldsInvalides = form.querySelectorAll(':invalid');
		fieldsInvalides.forEach(el => {
			let error = el.parentElement.querySelector(`.${settings.classes.errorField}`);
			if (el.type === 'radio') {
				error = document.getElementById(`${el.name}ErrorMsg`);
			}
			self._showError(el, error);
		});

		/// On filtre les différents éléments du formulaire
		/// pour ne garder que ceux concernés par une validation spécifique
		/// (hors Constraint validation API)
		const elements = Array.from(form.elements)
			.filter(element => {
				return element.type !== 'hidden' && element.type !== 'fieldset' &&
					element.localName !== 'button';
			});
		let fieldsInvalidCustom = elements.filter(x => !Array.from(fieldsInvalides).includes(x));

		if (settings.hasTelephoneMobile) {
			document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`).textContent = '';
			document.querySelector(settings.attribut.indicatif).parentElement.querySelector(`.${settings.classes.errorField}`).textContent = '';
			if (document.getElementById(settings.msg.fields.telephone.mobile).value == '') {
				document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`).textContent = settings.msg.noTelephone;
				document.querySelector(`#${settings.msg.fields.telephone.mobile}`).closest(settings.selectors.fieldClass).classList.add(settings.classes.error)
			} else {
				/// Si le format du mobile est incorrect
				if (document.getElementById(settings.msg.fields.telephone.mobile).value !== '' && !settings.regexTel.test(document.getElementById(settings.msg.fields.telephone.mobile).value)) {
					self._showError(document.querySelector(`#${settings.msg.fields.telephone.mobile}`), document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`));

				}
			}
		} /// Gestion des champs téléphoniques
		else if (settings.hasTelephone) {
			/// On reset le texte du message d'erreur (pour éviter message "au moins un téléphone" && "problème format")
			document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`).textContent = '';
			let indicatifFields = document.querySelectorAll(settings.attribut.indicatif);
			indicatifFields.forEach(el => el.parentElement.querySelector(`.${settings.classes.errorField}`).textContent = '')
			/// Si aucun numéro de téléphone n'est renseigné
			if (document.getElementById(settings.msg.fields.telephone.mobile).value == '' && document.getElementById(settings.msg.fields.telephone.fixe).value == '') {
				document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`).textContent = settings.msg.noTelephone;
			} else {
				/// Si le format du mobile est incorrect
				if (document.getElementById(settings.msg.fields.telephone.mobile).value !== '' && !settings.regexTel.test(document.getElementById(settings.msg.fields.telephone.mobile).value)) {
					self._showError(document.querySelector(`#${settings.msg.fields.telephone.mobile}`), document.querySelector(`#${settings.msg.fields.telephone.mobile} + .${settings.classes.errorField}`));
				}
				/// Si le format du fixe est incorrect
				if (document.getElementById(settings.msg.fields.telephone.fixe).value !== '' && !settings.regexTel.test(document.getElementById(settings.msg.fields.telephone.fixe).value)) {
					self._showError(document.querySelector(`#${settings.msg.fields.telephone.fixe}`), document.querySelector(`#${settings.msg.fields.telephone.fixe} + .${settings.classes.errorField}`));
				}
			}
		}

		/// Gestion des champs civilités
		if (settings.hasCivilite) {
			fieldsInvalidCustom.forEach(el => {
				const error = el.parentElement.querySelector(`.${settings.classes.errorField}`);
				if (el.dataset.formSelect === 'civilite') {
					let value = parseInt(el.value);
					if (value === 1 || value === 2) {
						self._isFieldValid(error, el);
					} else {
						self._showError(el, error);
					}
				}
			})
		}

		/// Gestion des champs où au moins un checkbox doit être coché
		if (settings.hasOwnProperty('form') && settings.form.hasOwnProperty('atLeast')) {
			const error = document.querySelector(`[data-parent-atLeast=${settings.form.atLeast}]`);
			if (self._hasAtLeastOneSelected(settings.form.atLeast)) {
				error.textContent = '';
			} else {
				error.textContent = error.dataset.formMsg;
			}
		}

		/// On scroll jusqu'à l'erreur
		if (settings.hasScrollToFirstError) {
			let errors = document.querySelectorAll(`.${settings.classes.error}`);

			for (let item of errors) {
				let error = item.querySelector(`.${settings.classes.errorField}`);
				if (error.textContent !== '') {
					firstError = item;
					firstError.scrollIntoView({
						behavior: 'smooth',
						block: "start",
						inline: "nearest"
					});
					return;
				}
			}
		}
	}

	/// @modal
	/// Function appelant une modale et affichant le contenu des champs en doublons
	/// Appelé sur la création de compte (création spontanée ou espace client)
	launchModale(callback) {
		let self = this;

		let nom = document.getElementById('Nom').value,
			prenom = document.getElementById('Prenom').value,
			nomUsage = document.getElementById('NomUsage').value,
			obj = self.controleSaisieCoordonnesDoublons(nom, prenom, nomUsage, '');

		if (obj.modale) {
			modalPopup.callback = function () {
				modalPopup.close();
				callback();
			}
			modalPopup.init(obj.messageDoublonNomSaisie, "", "Corriger", obj.libellebouton);
			console.error('Le formulaire est invalide');
		} else if (typeof callback === 'function') {
			callback();
		}
	}

	/// @submit
	/// Function qui renvoie un objet
	/// obj {modale: boolean, libellebouton: string || '', messageDoublonNomSaisie: string}
	controleSaisieCoordonnesDoublons(nom, prenom, nomUsage, idNomUsage) {
		/// la méthode includes est sensible à la casse
		nom = nom.toLowerCase();
		prenom = prenom.toLowerCase();

		/// Gestion du nom d'usage selon la civilité
		if ($("#NomUsage").is(":visible")) {
			nomUsage = nomUsage.toLowerCase();
		} else if (idNomUsage !== '' && idNomUsage !== null) {
			if (document.getElementById(idNomUsage) !== null && $(`#${idNomUsage}`).is(":visible")) {
				nomUsage = document.getElementById(idNomUsage).value.toLowerCase();
			} else {
				nomUsage = ""
			}
		} else {
			nomUsage = "";
		}
		/// Initialisation de l'object retourné par la function
		let obj = {};

		/// Paramètres de la function _getTextMessageErreur()
		let params = {
			attention: '<font color="red">Attention :</font> <br/>',
		}
		/// Selon la page (cas : demande de devis, création de compte, coordonnées passager sur le tunnel) sur laquelle nous sommes le libellé de la zone erronée est différent
		let page = document.getElementById('id1').value;
		if (page === 'account' || document.getElementById('id2').value === 'creationcompte') {
			params.localisation = '« Vos informations »';
		} else if (page === 'inscription') {
			//#Inscription_ColInfoVoyageur_${i}__NomUsage
			params.localisation = `Voyageur ${parseInt(idNomUsage.charAt(28)) + 1}`;
		}
		// else if (page === 'devisweb') {
		// 	params.localisation = 'rubrique « Vos coordonnées »';
		// }

		/// Cas possibles
		let arrayOfUseCase = [{
			caseLibelle: 'nomEqualPrenom',
			isTrue: (nom === prenom),
			case: 'equal'
		}, {
			caseLibelle: 'nomUsageEqualPrenom',
			isTrue: nomUsage === prenom,
			case: 'equal'
		}, {
			caseLibelle: 'nomEqualNomUsage',
			isTrue: nom === nomUsage,
			case: 'equal'
		}, {
			caseLibelle: 'nomContainPrenom',
			isTrue: nom !== "" && nom.includes(prenom) && nom !== prenom,
			case: 'contain'
		}, {
			caseLibelle: 'nomUsageContainPrenom',
			isTrue: nomUsage !== "" && nomUsage.includes(prenom) && nomUsage !== prenom,
			case: 'contain'
		}, {
			caseLibelle: 'prenomContainNom',
			isTrue: nom !== "" && prenom.includes(nom) && prenom !== nom,
			case: 'contain'
		}, {
			caseLibelle: 'prenomContainNomUsage',
			isTrue: nomUsage !== "" && prenom.includes(nomUsage) && prenom !== nomUsage,
			case: 'contain'
		}, {
			caseLibelle: 'nomUsageContainNom',
			isTrue: nomUsage !== "" && nom !== "" && nomUsage.includes(nom) && nomUsage !== nom,
			case: 'contain'
		}, {
			caseLibelle: 'nomContainNomUsage',
			isTrue: nomUsage !== "" && nom !== "" && nom.includes(nomUsage) && nom !== nomUsage,
			case: 'contain'
		}, ];

		if ((nom !== "" || nomUsage !== "") && prenom !== "") {
			obj.messageDoublonNomSaisie = this._getTextMessageErreur(arrayOfUseCase, params);
			obj.libellebouton = '';

			/// Dans le cas où le nom de jeune fille apparaît dans le nom d'épouse,
			/// on laisse la possibilité à l'utilisatrice de confirmer les champs renseignés
			arrayOfUseCase.forEach(function (element, index) {
				if (element.caseLibelle === 'nomUsageContainNom' && element.isTrue) {
					obj.modale = true;
					obj.libellebouton = "Confirmer " + nom + "/" + nomUsage;
				} else if (element.isTrue) {
					obj.modale = true;
				}
			})
		}

		return obj;
	}

	_getTextMessageErreur(array, config) {

		let libelleMessageErreur = '',
			caseEqual = false,
			caseContain = false;

		let element_1 = '',
			element_2 = '',
			msg = '',
			caseNomUsageContainNom = false;

		array.forEach(function (element, index) {
			if (element.isTrue) {
				if (element.case === 'equal') {
					switch (element.caseLibelle) {
					case 'nomEqualPrenom':
						element_1 = 'nom';
						element_2 = 'prénom';
						break;
					case 'nomUsageEqualPrenom':
						element_1 = 'nom d\'usage';
						element_2 = 'prénom';
						break;
					case 'nomEqualNomUsage':
						element_1 = 'noms de naissance';
						element_2 = 'd\'usage';
						break;
					default:
						console.error(`Sorry, we are out of ${element.caseLibelle}.`);
					}

					msg = `Les ${element_1} et ${element_2} sont identiques (${config.localisation}). <br/> Veuillez corriger cette information.`;
				}
				/// Cas où la value d'un champ est contenu dans un autre
				else {
					switch (element.caseLibelle) {
					case 'nomContainPrenom':
						element_1 = 'nom';
						element_2 = 'prénom';
						break;
					case 'nomUsageContainPrenom':
						element_1 = 'nom d\'usage';
						element_2 = 'prénom';
						break;
					case 'prenomContainNom':
						element_1 = 'prénom';
						element_2 = 'nom';
						break;
					case 'prenomContainNomUsage':
						element_1 = 'prenom';
						element_2 = 'nom d\'usage';
						break;
					case 'nomUsageContainNom':
						element_1 = 'nom d\'usage';
						element_2 = 'nom de naissance';
						caseNomUsageContainNom = true;
						break;
					case 'nomContainNomUsage':
						element_1 = 'nom de naissance';
						element_2 = 'nom d\'usage';
						break;
					default:
						console.error(`Sorry, we are out of ${element.caseLibelle}.`);
					}

					return msg = `Votre ${element_2} apparaît aussi dans le ${element_1} (${config.localisation}). <br/> Veuillez corriger cette information${caseNomUsageContainNom ? ' ou la confirmer' :''}.`;
				}
			}
		});

		libelleMessageErreur = `${config.attention} ${msg}`;
		return libelleMessageErreur;
	}

	/// Gestion dqe
	dqe(idForm, object, array) {
		document.getElementById(idForm).addEventListener('change', function (e) {

			if (e.target.id == 'NumeroLibelleVoie') {
				e.target.dispatchEvent(
					new CustomEvent('adresseChange', {
						bubbles: true
					})
				)
			}
		});
		document.addEventListener('adresseChange', function (e) {
			// Liste des id des champs remplis par le DQE
			let arr = ['CodePostal', 'Ville'];
			if (array !== undefined) {
				arr = array
			}

			arr.forEach(el => {
				let parent = document.getElementById(el).parentElement;
				if (el.value !== '') {
					parent.classList.remove('is-error');
					if (parent.querySelector('.field-validation-error') !== null) {
						parent.querySelector('.field-validation-error').style.display = 'none';
					}
				}
			});
		})
	}


	/// ==========================================================================

	/// @Fields
	/// Function appelant les functions de validation correspondant au type d'un champ {el} donné
	validateField(el) {
		let self = this;
		let settings = this.settings;

		/// Pour chaque ${el}, on créer le bloc message erreur s'il n'existe pas
		self._createErrorMsg(el);
		let error = el.parentElement.querySelector(`.${settings.classes.errorField}`);
		let typeField = el.type;

		switch (typeField) {
		case 'select-one':
			self._validateSelect(el, error);
			break;
		case 'text':
			self._validateInput(el, error);
			break;
		case 'radio':
			error = document.getElementById(`${el.name}ErrorMsg`);
			self._validateCheckbox(el, error);
			break;
		case 'checkbox':
			self._validateCheckbox(el, error);
			break;
		case 'password':
			self._validateInput(el, error);
			break;
		case 'date':
			self._validateInput(el, error);
			break;
		case 'email':
			self._validateEmail(el, error);
			break;
		case 'tel':
			self._validateTel(el, error);
			break;
		case 'number':
			self._validateNumber(el, error);
			break;
		case 'textarea':
			self._validateTextarea(el, error);
			break;
		default:
			console.log(`Sorry, we are out of ${typeField}. : ${el.id}`);
		}
	}
	/// @Fields, @event
	/// Function de validation d'un <input type="text">
	_validateTextarea(input, errorSelector) {
		let self = this;

		let events = ['blur', 'focusin', 'focusout', 'keyup'];
		for (let event of events) {
			/// Au focus dans le champ, on masque le message d'erreur
			input.addEventListener(event, () => {
				self._isFieldValid(errorSelector, input);

				if (input.validity.valueMissing && event === 'focusout') {
					self._showError(input, errorSelector);
				}
			});
		}

	}
	/// @Fields, @event
	/// Function de validation d'un <input type="text">
	_validateInput(input, errorSelector) {
		let self = this;

		/// Au focus dans le champ, on masque le message d'erreur
		input.addEventListener('focusin', () => {
			self._isFieldValid(errorSelector, input);
		});

		/// Lorsque l'utilisateur tape sur son clavier,
		/// on vérifie qu'il n'entre pas des caractères interdits
		if (input.id !== 'DateNaissance' && input.id.indexOf('DateNaissance') < 0) {
			input.addEventListener('keyup', () => {
				if (input.validity.patternMismatch) {
					self._showError(input, errorSelector);
				} else {
					self._isFieldValid(errorSelector, input);
				}
			});
		}

		/// Lorsque le champ perd le focus
		/// (à la différence de focus, l'événement blur ne se propage pas)
		input.addEventListener('blur', () => {
			/// Gestion cas input date de naissance
			if (input.id === 'DateNaissance' || input.id.indexOf('DateNaissance') > 0 || input.id.indexOf('naissance') > 0) {
				let items = input.value.split('/');
				let date = new Date(`${items[2]}/${items[1]}/${items[0]}`).toLocaleDateString(
					'fr-FR'
				);
				let msg = 'La date indiquée est invalide';
				if (input.value === '') {
					errorSelector.textContent = 'La date de naissance est obligatoire';
					self._showError(input, errorSelector);
				} else if (date === 'Invalid Date') {
					errorSelector.textContent = msg;
				} else if (date !== input.value) {
					errorSelector.textContent = msg;
					self._showError(input, errorSelector);
				} else if (new Date(input.value) > Date.now()) {
					errorSelector.textContent = `La date de naissance doit être antérieure à aujourd'hui.`;
					self._showError(input, errorSelector);
				} else if (parseInt(items[2]) < 1900) {
					errorSelector.textContent = `L'année de naissance doit être postérieure à 1900.`;
					self._showError(input, errorSelector);
				} else {
					self._isFieldValid(errorSelector, input);
				}
			} else if (parseInt(input.value) < parseInt(input.dataset.min)) {
				self._showError(input, errorSelector);
			} else if (input.validity.valid) {
				self._isFieldValid(errorSelector, input);
			} else {
				self._showError(input, errorSelector);
			}
		});
	}

	/// Function de validation d'un <input type="checkbox">
	_validateCheckbox(input, errorSelector) {
		let self = this;

		/// Lorsque l'utilisateur coche
		input.addEventListener('change', (el) => {
			if (input.validity.valid) {
				self._isFieldValid(errorSelector, input);
			} else {
				self._showError(input, errorSelector);
			}
		});
	}
	/// @Fields, @event
	/// Function de validation d'un <select>
	_validateSelect(select, errorSelector) {
		let self = this;
		let isCivilite = select.dataset.formSelect === 'civilite';
		select.addEventListener('change', (el) => {
			let target = el.target;

			if (isCivilite) {
				let value = parseInt(target.value);
				if (value === 1 || value === 2) {
					self._isFieldValid(errorSelector, target);
				} else {
					self._showError(target, errorSelector);
				}
			}
			/// Dans les autres cas d'un select,
			/// On teste simplement si la value est non vide ou non définie
			else if (target.value === '' || target.value === undefined) {
				self._showError(target, errorSelector);
			} else {
				self._isFieldValid(errorSelector, target);
			}
		});
	}
	/// @Fields, @event
	/// Function de validation d'un <input type="email">
	_validateEmail(emailInput, errorSelector) {
		let self = this;

		/// Au focus dans le champ, on masque le message d'erreur
		emailInput.addEventListener('focusin', () => {
			self._isFieldValid(errorSelector, emailInput);
		});

		/// Lorsque le champ perd le focus
		/// (à la différence de focus, l'événement blur ne se propage pas)
		emailInput.addEventListener('blur', () => {
			if (emailInput.validity.valid) {
				self._isFieldValid(errorSelector, emailInput);
			} else {
				self._showError(emailInput, errorSelector);
			}
		});
	}
	/// @Fields, @event
	/// Function de validation d'un <input type="number">
	_validateNumber(inputNumber, errorSelector) {
		let self = this;
		let settings = this.settings;

		/// Au focus dans le champ, on masque le message d'erreur
		inputNumber.addEventListener('focusin', () => {
			self._isFieldValid(errorSelector, inputNumber);
		});

		/// Gestion de l'erreur en cas de caractères interdits
		inputNumber.addEventListener('keyup', (el) => {
			if (inputNumber.value === '') {
				self._showError(inputNumber, errorSelector);
			} else {
				self._isFieldValid(errorSelector, inputNumber);
			}
		});

		inputNumber.addEventListener('blur', (el) => {
			if (el.target.validity.rangeUnderflow || inputNumber.value === '') {
				self._showError(inputNumber, errorSelector);
			} else {
				self._isFieldValid(errorSelector, inputNumber);
			}
		});
	}
	/// @Fields, @event
	/// Function de validation d'un <input type="tel">
	_validateTel(telInput, errorSelector) {
		let self = this;
		let settings = this.settings;

		/// Au focus dans le champ, on masque le message d'erreur
		telInput.addEventListener('focusin', () => {
			self._isFieldValid(errorSelector, telInput);
		});

		/// Gestion de l'erreur en cas de caractères interdits
		telInput.addEventListener('keyup', (el) => {
			let charactersForbidden = settings.regex.charactersAlpha;
			if (!charactersForbidden.test(telInput.value)) {
				self._isFieldValid(errorSelector, telInput);
			} else {
				self._showError(telInput, errorSelector);
			}
		});

		telInput.addEventListener('blur', (el) => {
			if (settings.regexTel.test(telInput.value)) {
				self._verificationIndicatifTelephonique();
			} else {
				self._showError(telInput, errorSelector);
			}
			/// On affiche un message d'erreur au blur uniquement s'il y a une value
			if (el.target.value === '') {
				self._isFieldValid(errorSelector, telInput);
			}
		});
	}
	/// @Fields, @boolean
	/// Function gérant l'affichage des messages d'erreur liés aux indicatifs téléphoniques
	_verificationIndicatifTelephonique() {

		let settings = this.settings;
		let msg = settings.msg;

		function checkIndicatif(idTel, idIndicatif) {
			return document.getElementById(idTel).value !== '' && document.getElementById(idIndicatif).value === '';
		}

		function returnMsg(id, message) {
			document.getElementById(id).innerText = message;
		}

		if (!settings.noCheckIndicatif) {

			if (msg.fields.telephone.mobile !== undefined) {
				if (checkIndicatif(msg.fields.telephone.mobile, msg.fields.indicatif.mobile)) {
					returnMsg(msg.validationIdMsg.mobile, msg.indicatif);
				} else {
					returnMsg(msg.validationIdMsg.mobile, '');
				}
			}

			if (msg.fields.telephone.fixe !== undefined) {
				if (checkIndicatif(msg.fields.telephone.fixe, msg.fields.indicatif.fixe)) {
					returnMsg(msg.validationIdMsg.fixe, msg.indicatif);
				} else {
					returnMsg(msg.validationIdMsg.fixe, '');
				}
			}
		}


	}

	/// ==========================================================================

	/// @Validation
	/// Function permettant d'ajouter/retirer une classe au bouton de validation
	/// selon un @Bolean (formulaire valide ou invalide)
	// stateValidButton(bool, btn) {
	// 	let settings = this.settings;

	// 	if (btn !== null) {
	// 		bool ? btn.classList.remove(settings.classes.disabled) : btn.classList.add(settings.classes.disabled);
	// 	} else {
	// 		console.error(`Le button de validation 'btn' est null`)
	// 	}
	// }
	/// @Validation
	/// Retourne un boolean
	_isFormValid(formulaire) {
		let self = this;
		let settings = this.settings;

		let arr = [formulaire.checkValidity()];

		if (settings.hasTelephone) {
			arr.push(self._isTelephoneFieldValid());
		}
		if (settings.hasCivilite) {
			arr.push(self._isCiviliteFieldValid(formulaire));
		}
		if (settings.hasOwnProperty('form') && settings.form.hasOwnProperty('atLeast')) {
			arr.push(self._hasAtLeastOneSelected(settings.form.atLeast))
		}

		/// Vaut true si tous les items de [arr] valent true
		let validation = arr.every(bool => bool);

		return validation;
	}
	/// @Validation
	/// Retourne un boolean
	/// au mois un checkbox checked
	_hasAtLeastOneSelected(attribut) {
		let arr = [];
		document.querySelectorAll(`[data-form-atLeast=${attribut}]`).forEach(el => arr.push(el.checked))
		const oneChecked = (element) => element === true;
		return arr.some(oneChecked);
	}

	/// @Validation
	/// Retourne un boolean
	_isTelephoneFieldValid() {
		let settings = this.settings;
		let msg = this.settings.msg;


		let valueMobile = Object.is(document.getElementById(msg.fields.telephone.mobile), null) ? '' : document.getElementById(msg.fields.telephone.mobile).value;
		let valueFixe = Object.is(document.getElementById(msg.fields.telephone.fixe), null) ? '' : document.getElementById(msg.fields.telephone.fixe).value;

		let hasNumero = !(valueMobile === '' && valueFixe === '');
		let isValid = hasNumero;

		if (hasNumero) {
			let numeroIsValid = true,
				mobileIsValid = true,
				fixeIsValid = true;

			if (document.getElementById(msg.fields.telephone.mobile) !== null && document.getElementById(msg.fields.telephone.mobile).value !== '') {
				mobileIsValid = settings.regexTel.test(document.getElementById(msg.fields.telephone.mobile).value);
			}
			if (document.getElementById(msg.fields.telephone.fixe) !== null && document.getElementById(msg.fields.telephone.fixe).value !== '') {
				fixeIsValid = settings.regexTel.test(document.getElementById(msg.fields.telephone.fixe).value);
			}

			numeroIsValid = mobileIsValid && fixeIsValid;
			isValid = hasNumero && numeroIsValid;
		}

		return isValid;
	}
	/// @Validation
	/// retourne un boolean si un champ[settings.attribut.select] existe
	/// sinon retourne @null
	_isCiviliteFieldValid(formulaire) {
		let settings = this.settings;

		let select = formulaire.querySelector(settings.attribut.select);
		if (select.dataset.formSelect === 'civilite') {
			let civilite = select;
			let value = parseInt(civilite.value);
			if (value === 1 || value === 2) {
				return true;
			} else {
				return false;
			}
		} else {
			return null;
		}
	}
	/// @Validation
	_isFieldValid(error, input) {
		let settings = this.settings;

		if (error === null) {
			throw new Error(`Le champ ${input.name} n'a pas de <span> error`);
		}
		// si le champ est valide,
		// on vide le message d'erreur et l'on retire la classe `settings.classes.error`
		error.textContent = '';
		input.parentElement.classList.remove(settings.classes.error);

		if (input.type === 'tel') {
			input.closest(settings.selectors.fieldClass).classList.remove(settings.classes.error);
		} else if (input.type === 'radio') {
			input.closest(`.${settings.classes.error}`) !== null && input.closest(`.${settings.classes.error}`).classList.remove(settings.classes.error);
		} else if (input.type === 'date' || input.type === 'number') {
			input.closest(`.${settings.classes.error}`) !== null && input.closest(`.${settings.classes.error}`).classList.remove(settings.classes.error);
		}
	}

	/// ==========================================================================

	/// @Erreurs
	/// On génère dynamiquement le {selector} d'erreur
	/// lors d'un retour serveur, ce dernier est non null (<%= this.Html.ValidationMessageFor(model => model)%>)
	_createErrorMsg(selector) {
		let settings = this.settings;

		if (selector.parentElement.querySelector(`.${settings.classes.errorField}`) === null) {
			let span = document.createElement('span');
			let container = selector.parentElement;
			if (container.querySelector('.field-validation') !== null) {
				container = container.querySelector('.field-validation')
			}
			container.appendChild(span);
			container.querySelector('span').classList.add(settings.classes.errorField);
			container.querySelector('span').setAttribute('aria-live', 'polite'); // Accessibilité, voir https://developer.mozilla.org/fr/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
		}
	}
	/// @Erreurs
	/// Génération du message d'erreur
	_showError(el, error) {
		let self = this;
		let settings = this.settings;
		let format = '';
		let libelle = '';
		if (el.dataset.formIndicatif && el.value === '') {
			libelle = 'indicatif';
			error.textContent = `L'${libelle} est obligatoire.`;
			el.parentElement.parentElement.querySelector(`[type="tel"] + .${settings.classes.errorField}`).textContent = '';
		} else if (el.type !== 'tel') {
			if (el.parentElement.querySelector('label') != null) {
				libelle = el.parentElement.querySelector('label').innerText.toLowerCase();
			}
		} else {
			libelle = 'téléphone';
		}

		/// Pattern
		if (el.pattern === '[a-zA-ZÀ-ÖØ-öø-ÿ -]+') {
			format = 'lettres, espace ou tiret';
		} else if (el.pattern === '[0-9]{1,}') {
			format = 'chiffres';
		} else {
			format = el.pattern;
		}

		/// Type
		if (el.type === 'tel') {
			format = settings.msg.formatTelephone;
			if (el.parentElement.parentElement.querySelector(`${settings.attribut.indicatif} + .${settings.classes.errorField}`) !== null) {
				el.parentElement.parentElement.querySelector(`${settings.attribut.indicatif} + .${settings.classes.errorField}`).textContent = '';
			}
			error.textContent = format;
		} else if (el.type === 'date') {
			let msgError = 'La date est invalide.';
			let date = new Date(`${el.value}`).toLocaleDateString('fr-FR');
			if (new Date(el.value) <= Date.now()) {
				msgError = `La date doit être postérieure à aujourd'hui.`;
			}
			error.textContent = msgError;
		}

		let arrayFieldFem = ['civilité', 'ville', 'destination'];
		let arrayFieldVoy = ['a', 'e', 'i', 'o', 'u'];
		/// La valeur est manquante
		if (el.validity.valueMissing) {
			if (el.dataset.formMsg) {
				error.textContent = el.dataset.formMsg;
			} else if (el.type === 'radio') {
				libelle = document.getElementById(`${el.name}ErrorMsg`).dataset.formMsg;
				error.textContent = libelle === '' ? 'Ce champs est obligatoire' : libelle;
			} else if (arrayFieldVoy.includes(libelle.charAt(0))) {
				error.textContent = `L'${libelle} est obligatoire.`;
			} else if (arrayFieldFem.includes(libelle)) {
				error.textContent = `La ${libelle} est obligatoire.`;
			} else {
				error.textContent = `Le ${libelle} est obligatoire.`;

				if (el.type === 'tel') {
					el.closest('[data-fieldset="telephone"]').classList.add('is-error');
				}
			}
		}

		/// Format invalide (email, tel, number)
		else if (el.validity.typeMismatch) {
			error.textContent = `Le format ${el.type} est invalide.`;
		}
		/// Le format ne correspond pas au motif de la value
		else if (el.validity.patternMismatch && el.placeholder !== 'jj/mm/aaaa') {
			if (el.dataset.format === 'identite') {
				error.textContent = `Seules les lettres, espace et tiret sont autorisés.`;
			} else if (el.dataset.format === 'number') {
				error.textContent = `Seuls les chiffres sont autorisés.`;
			} else {
				error.textContent = `Votre ${libelle} ne doit comporter que des ${format}.`
			}
		} else if (el.dataset.format === 'number' && el.value !== '' && (parseInt(el.value) < parseInt(el.dataset.min))) {
			error.textContent = `Le nombre minimal est de ${el.dataset.min}`;
		}
		/// La value est trop longue
		else if (el.validity.tooLong) {
			error.textContent = `Votre ${libelle} doit comporter moins de ${el.maxLength} caractères, vous en avez entré ${el.value.length}.`;
		}
		/// La value est trop courte
		else if (el.validity.tooShort) {
			error.textContent = `Votre ${libelle} doit comporter au moins ${ el.minLength } caractères, vous n'en avez entré que ${el.value.length}.`;
		}
		///
		/// Gestion des cas spécifiques (hors Constraint validation API)
		else if (el.dataset.formSelect === 'civilite') {
			error.textContent = `La civilité est obligatoire.`;
		} else if (el.dataset.formSelect === 'pays') {
			error.textContent = `Le pays est obligatoire.`;
		}

		/// La valeur n'est pas bonne (type=number)
		if (el.validity.badInput && el.type === 'number') {
			error.textContent = `Seuls les chiffres sont autorisés.`;
			if (el.parentElement.querySelector('label') === null) {
				el.parentElement.parentElement.classList.add('is-error')
			}
		} else if (el.validity.rangeUnderflow && el.type === 'number') {
			error.textContent = el.dataset.formMsg ?? `La valeur minimale est de ${el.min} ${el.dataset.unite}`;

			if (el.parentElement.querySelector('label') === null) {
				el.parentElement.parentElement.classList.add('is-error')
			}
		}

		/// On ajoute la classe .is-error pour modifier la couleur des éléments du bloc concerné
		let block = el.parentElement;
		if (el.type === 'fieldset') {
			block = el;
		}
		block.classList.add(settings.classes.error);
		error.style.display = 'block';
	}

	/// ==========================================================================

	/// @Indicatif Téléphonique
	/// @event
	/// Appelle la function _boxInput pour
	/// tous les indicatifs téléphoniques présents et lors d'un change du select
	/// isCallback : la function appelée lors d'un changement d'un select pays permet de récupérer la bonne valeur et de mettre l'indicatif tél. correspondant
	indicatifTelephonique(isCallback, value) {
		let self = this;
		let settings = this.settings;

		let selectIndicatif = document.querySelectorAll(settings.attribut.indicatif);

		selectIndicatif.forEach(function (el) {
			let box = el.parentElement.querySelector(settings.attribut.boxinput);
			if (isCallback) {
				el.value = value;
				self._boxInput(el, box)
			} else {
				el.addEventListener('change', function (element) {
					self._boxInput(element.target, box)
				})
			}
		});
	}

	/// @Indicatif Téléphonique
	/// Appelée dans indicatifTelephonique
	/// Affiche seulement l'indicatif et non "Pays (+Indicatif)"
	_boxInput(el, box) {
		let text = el.options[el.selectedIndex].text;
		if (text != '----' && text != '--') {
			let value = text.split('(');
			let indicatifNumerique = value[1].slice(0, -1);
			if (box != null) {
				box.innerText = indicatifNumerique;
			}
		} else {
			box.innerText = '';
		}
	}

	/// Au cas où le champ soit en erreur,
	/// au focus, on retire l'erreur
	_callbackFocus(el) {
		let self = this;
		let selectors = self.settings.selectors;

		const field = el.parentNode.classList.contains(selectors.fieldClassName) ? el.parentNode : el.parentNode.parentNode;
		field.classList.remove(selectors.fieldErrorClassName);
	}

	/// UTILITIES
	/// permet de détécter si un input a un placeholder
	_placeholderActive(selector) {
		return !!document.querySelector(selector + ':placeholder-shown');
	}

}